export default {
  message: {
    welcome: 'Welcome to our app!',
    dashboard: 'Dashboard',
    my_secrets: 'My secrets',
    my_company_secrets: 'My company secrets',
    my_documents: 'My Documents',
    documents: 'Documents',
    trash: 'Trash',
    logs: 'Action history',
    vault_management: 'Management of the safe',
    help: 'Help',
    release_logs: 'Novelties',
    faq: 'FAQ',
    user_guide: 'User instructions',
    contact: 'Contact',
    legal_notices: 'Legal notices',
    cgu: 'GTS',
    admin: 'Administration',
    collaborators_management: 'Staff management',
    personal_storage_space: 'PERSONAL STORAGE SPACE',
    buy_storage: 'Buy storage',
    employee_management: 'Employee management',
    page_management: 'Page management',
    release_log_management: 'Novelties management',
    statistics: 'Statistics Management',
    statistics_vault_chart: 'Number of vaults',
    statistics_connection_chart: 'Number of connections',
    statistics_secret_chart: 'Number of secrets',
    statistics_document_chart: 'Number of documents',
    massive_upload: 'Massive upload',
    usage: '{used} MB used of {max} GB',
    percent_used: '{percent}% used',
    file_singular: '{count} file',
    file_plural: '{count} files',
    section_payslip: 'Payslips',
    section_individual_com: 'Individual communication',
    section_general_com: 'General communication',
    section_personal: 'Personal',
    section_bils: 'Invoice for computer and telephone equipment',
    section_contracts: 'Contracts',
    section_quote: 'Quote',
    section_accounting: 'Accounting',
    section_internal_com: 'Internal communication',
    section_fiducial_documents: 'FIDUCIAL documents',
    section_my_company: 'My company',
    section_secrets: 'My secrets',
    master_key: 'Master key',
    master_key_init: 'Initialization',
    master_key_update: 'Update',
    master_key_check: 'Check',
    search: 'Search',
    all_rights_reserved: 'All rights reserved',
    files_import: 'Files import',
    login: 'Log in',
    log_out: 'Log out',
    edit_your_email: 'Change your email',
    edit_your_password: 'Change your password',
    notification_options: 'Notification options',
    subscription_services: 'Subscription to services',
    trusted_third_party: 'Trusted third party',
    two_factor_authentication: 'Two-factor authentication',
    my_account: 'My account',
    my_collaborators: 'My team',
    others: 'Other',
    services: 'Services',
    discover_also: 'Discover also',
    employee_or_former_fiducial: 'I am a current or former FIDUCIAL employee',
    fiducial_client: 'I am a FIDUCIAL client',
    session_expired: 'Your session has expired, please log in again',
    forgot_password: 'Forgot your password?',
    not_registered_yet: 'Not yet registered?',
    account_activation_via_temp: 'Account activation via your temporary login',
    employee_contact: 'Employee contact',
    client_contact: 'Client contact: get in touch with your branch',
    fiducial_login: 'Log in with my Fiducial account',
    label_email: 'Email',
    label_password: 'Password',
    label_password_confirmation: 'Password confirmation',
    label_name: 'Name',
    label_url: 'Url',
    label_login: 'Login',
    label_comment: 'Comment',
    password_reset_instructions: 'If your email address is referenced in the database, a reset email has just been sent to you. Click on the link in this email to reset your password.',
    account_blocked: 'Account blocked for 15min',
    account_step_1_desc: 'Before you start activating the service, please make sure you have access to your personal email where you will receive a confirmation email valid for 3 hours',
    temp_login: 'Enter your temporary login',
    temp_password: 'Temporary password',
    back_to_login: 'Back to login',
    next: 'Next',
    activation_step_1: 'Activation step 1',
    mandatory_login: 'Login is mandatory',
    mandatory_password: 'Password is mandatory',
    mandatory_current_password: 'Current password is mandatory',
    mandatory_password_confirmation: 'Password confirmation is mandatory',
    mandatory_email: 'Email is mandatory',
    mandatory_valid_email: 'Email must be valid',
    mandatory_format_valid_email: 'Email must have a valid format',
    mandatory_first_name: 'First name is mandatory',
    mandatory_last_name: 'Last name is mandatory',
    mandatory_address: 'Address is mandatory',
    mandatory_phone: 'Phone is mandatory',
    mandatory_message: 'Message is mandatory',
    account_step_2_desc: 'In order to activate your account, you must enter your email and set your own password.',
    activation_step_2: 'Activation step 2',
    validate: 'Confirm',
    password_must_match: 'Passwords must be identical',
    password_must_be_different_email: 'Password must be different from email address',
    password_not_strong_enough: 'Password is not complex enough.',
    minimal_password_level: 'Minimum password security level',
    weak: 'Weak',
    strong: 'Strong',
    between_10_32_char: 'Between 10 and 32 characters',
    at_least_one_caps: 'At least one uppercase letter',
    at_least_one_lowercase: 'At least one lowercase letter',
    at_least_one_digit: 'At least one digit',
    at_least_one_special_char: 'At least one special character',
    redirecting: 'Redirection to your space Mon eCoffre in progress',
    forgot_password_title: 'Forgot password',
    fill_form_to_reset_password: 'If you have forgotten your password, please complete the form below to receive an email to reset your password.',
    reset_link_info: 'Reset link is only valid for 5 hours. After this deadline, you will need to make a new request.',
    reset_password: 'Password reset',
    return_to_login: 'Back to login',
    activation: 'Activation',
    budget_code_intro: 'The Coffre-fort Code Budget is an internal FIDUCIAL service for the reception of documents necessary for the correct management of the departments of the entities, activities, companies, managers... for the entire firm.',
    more_info: 'For further information, please click on the following links',
    code_budget_user_guide: 'Coffre-fort Code Budget user instructions',
    faq_other: 'Frequently Asked Questions (FAQ)',
    login_with_intranet: 'Log in to my intranet account',
    error_login: 'An error occurred while logging in. Please try again later.',
    no_budget_code: 'No Safe',
    access_expired: 'Your access to this Safe has expired.',
    redirecting_to_your_space: 'Redirection to your space Mon eCoffre in progress',
    connection_error: 'An error occurred while logging in. Please try again later.',
    no_access_granted: 'No access was allowed for this Safe.',
    reset_password_info: 'Following your reset request, please enter your new password.',
    new_password: 'New password',
    confirm_new_password: 'New password confirmation',
    security_policy_text: 'As part of our security improvement policy, we ask you to change your password in accordance with the criteria below.',
    update_current_password: 'Password update',
    change_password: 'Password modification',
    fill_out_form: 'Complete the form below to change your password.',
    current_password: 'Current password',
    change_email: 'Email modification',
    currently_logged: 'You are currently logged in with the following email:',
    email_usage: 'Your email is your login. It is also used to send you notifications related to your account.',
    validate_email: 'Email validation',
    validate_token: 'Token validation',
    admin_collaborators: 'Staff administration',
    no_collaborator: 'You currently have no staff members having an account for this safe. You can add a staff member in',
    your_fiducial_account: 'your Fiducial account.',
    collaborator: 'Staff member',
    email: 'Email',
    created_at: 'Created on',
    last_login: 'Last connection',
    clearance_added: 'Authorization has been added.',
    clearance_revoked: 'Authorization has been revoked.',
    error_clearance_deletion: 'An error occurred while withdrawing the authorization. Please try again later.',
    invite_collaborator: 'Invite a staff member',
    no_collaborators: 'No staff members available',
    owner: 'Owner',
    delete: 'Delete',
    expires_on: 'Expires on',
    no_date: 'no date',
    last_connection: 'Last connection',
    administrators: 'Administrators',
    edit: 'Change',
    invited_by: 'Invited by',
    users: 'Users',
    expired: 'Expired',
    delete_access: 'Delete access',
    user_invitation: 'User invitation',
    modify_expiration_date: 'Change expiration date',
    email_star: 'Email',
    expiration_date_limitation: 'Expiration date (if field empty: no time limit for access)',
    user_type: 'Type of user',
    type_required: 'Type is required',
    cancel: 'Cancel',
    update_saved: 'Update has been saved.',
    error: 'Error',
    contact_support: 'Contact Support',
    new_request: 'Make a new request',
    are_you_employee: 'Are you an employee?',
    yes: 'Yes',
    no: 'No',
    access_issue: 'Only if you cannot access Intranet Workflows,',
    click_here: 'click here',
    click_here_login_issue: 'Click here if you can\'t log in',
    other_problems: 'For any other problem, please introduce a workflow on the Intranet “Informatique et téléphonie : Assistance : panne, dysfonctionnement, autres. »',
    no_access_intranet: 'If you do not have access to the intranet or to this workflow, ask your manager to make the request.',
    workflow_reminder: 'We remind you that you have to enter your request via Workflow if you have access to the Fiducial Intranet.',
    last_name: 'Name',
    first_name: 'First name',
    employee_id: 'Employee number',
    phone: 'Phone',
    category: 'Category',
    category_required: 'Category is required',
    message: 'Message',
    agreement: 'By confirming, I agree to the processing of my personal data in accordance with the',
    privacy_policy_link: '/privacy-policy',
    privacy_policy: 'Fiducial Cloud\'s Privacy Policy',
    send: 'Send',
    category_account_creation: 'Account Creation',
    category_login: 'Log in',
    category_document_reception: 'Reception of documents',
    category_account_management: 'Account Management',
    category_personal_data_management: 'Management of my personal data',
    category_missing_pay_slip: 'Missing payslip',
    category_error_message: 'Error message',
    category_other_issues: 'Other issue(s)',
    connection_issue_instructions: 'For connection problems, please use the standard intervention procedures (Workflow Assistance: Panne, dysfonctionnement, autres).',
    new_access_requests: 'Regarding new requests for access to an existing safe, 3 solutions are possible (see User instructions):',
    delegation_rights_option_1: 'Delegation of rights by the former owner of the Safe to the new budget code manager (invitation with the “Administrators” user type).',
    delegation_rights_option_2: 'Delegation of rights by the current budget code manager for a staff member (invitation with the “Users” user type).',
    delegation_rights_option_3: 'If a new budget code manager has not been able to access the safe by delegation, send a request by email to: gestion.interne.parc.materiel.informatique@fiducial.fr',
    message_received: 'Your message has been taken into account.',
    fiducial_privacy_policy: 'Privacy Policy',
    terms_of_use: 'General Terms of Service of Mon eCoffre par FIDUCIAL',
    presentation_my_ecoffre: 'Introducing Mon eCoffre',
    presentation_coffre_code_budget: 'Introducing Coffre-fort Code Budget',
    presentation_paragraph_1: 'Mon eCoffre par FIDUCIAL is an online safe to back up and protect your important data and documents in a highly secure environment. Mon eCoffre par FIDUCIAL is a platform accessible from any device connected to the internet. In accordance with the law, the delivery of documents by FIDUCIAL is carried out under conditions that guarantee data integrity',
    ecoffre_url: 'https://www.mon-ecoffre.com',
    presentation_paragraph_2: 'The user will receive an email notification each time FIDUCIAL drops something in the safe. For further questions about the use of eCoffre, please go to the various sections of our FAQ.',
    presentation_paragraph_3: 'The Coffre-fort Code Budget is an internal FIDUCIAL service allowing to receive documents necessary for the correct management of the departments of the entities, activities, companies, managers, etc… for all the entities and companies within the firm. For further information, please click on the following link for',
    code_budget_user_guide_2: 'Coffre-fort Code Budget user instructions',
    budget_user_guide_url_code: '/user_instructions_Budget_Code_Safe.pdf',
    view_presentation_videos: 'View the presentation videos',
    frequently_asked_questions: 'Frequently Asked Questions',
    my_ecoffre: 'Mon eCoffre',
    notification_email_login: 'The email notification when logging in to your account is',
    notification_email_new_document: 'The email notification when a new document is placed in your safe, is',
    business: 'company',
    notification_email_secret_creation: 'The email notification when a secret is created in your safe, is',
    notification_email_secret_deletion: 'The email notification when a secret is deleted in your safe, is',
    notification_email_secret_view: 'The email notification when a secret is read in your safe, is',
    notification_email_secret_update_by_owner: 'The email notification when a secret is changed by yourself in your safe, is',
    notification_email_secret_update_by_collab: 'The email notification when a secret is changed in your safe by a staff member, is',
    token: 'Enter a code obtained with the application to validate two-factor authentication',
    to_enable: 'Enable',
    activated: 'activated',
    deactivated: 'deactivated',
    notification_option_updated: 'Your notification option has been updated.',
    service_subscription: 'Subscription to services',
    dematerialization_of_pay_slips: 'Dematerialization of payslips',
    subscribed_to_dematerialization: 'You have subscribed to dematerialization of your payslips.',
    not_subscribed_to_dematerialization: 'You have not subscribed to dematerialization of your payslips. You will receive them by post at home.',
    service_coming_soon: 'Service coming soon',
    subscription_updated: 'Your subscription has been updated.',
    trusted_third_party_optional: 'Trusted third party (optional)',
    trusted_third_party_info: 'You have the option to enter, at any time, a trusted person\'s information regarding access to your electronic safe.',
    you_have_designated_as_trusted: 'You have appointed',
    address: 'Address',
    validate_information: 'Confirm the information',
    trusted_third_party_created: 'Trusted third party has been created.',
    trusted_third_party_updated: 'Trusted third party has been updated.',
    date_from: 'From',
    date_to: 'To',
    search_2: 'Search',
    reset: 'Reset',
    date_hour: 'Date time',
    action: 'Action',
    user: 'User',
    ip: 'IP',
    browser: 'Browser',
    details: 'Details',
    elements: 'item(s)',
    see_more: 'View more',
    updated_fields: 'Field(s) changed',
    copied_fields: 'Field(s) copied',
    field_name: 'Name',
    field_url: 'Url',
    field_login: 'Login',
    field_password: 'Password',
    field_comment: 'Comment',
    type_document: 'Document',
    type_folder: 'Folder',
    type_secret: 'Secret',
    name: 'Name',
    deposit_date: 'Date of deposit',
    actions: 'Actions',
    download: 'Download',
    view_file_location: 'Show file location',
    view: 'View',
    information: 'Information',
    service_maintenance: 'The service is currently under maintenance, your documents are not available for viewing at this time.',
    error_fetching_items: 'An error occurred while recovering items.',
    trash_date: 'Trash Date',
    automatic_deletion_date: 'Automatic deletion date',
    restore_selection: 'Restore the selection',
    delete_selection_perma: 'Permanently delete the selection',
    empty_trash: 'Empty Trash',
    document_restoration_error: 'An error occurred while recovering the document.',
    elements_restoration_error: 'An error occurred while recovering items.',
    create_secret: 'Creation of a Secret',
    create_new_folder: 'Create a new folder',
    delete_selected_secrets: 'Delete the selected secrets',
    delete_selection: 'Delete the selection',
    move_selection: 'Move the selection',
    creation_date: 'Date of creation',
    new: 'New',
    drop_date: 'Date of deposit',
    rename: 'Rename',
    copy_password: 'Copy the password',
    copy_username: 'Copy the username',
    copy_url: 'Copy the URL',
    copy_comment: 'Copy the comment',
    mandatory_folder_name: 'Last name is mandatory',
    selection_moved: 'The selection has been moved.',
    error_moving_selection: 'An error occurred while moving the selection.',
    error_retrieving_items: 'An error occurred while recovering items.',
    maintenance_secrets_unavailable: 'The service is currently under maintenance, your secrets are not available for viewing at this time.',
    url_copied_successfully: 'URL successfully copied!',
    username_copied_successfully: 'Username successfully copied!',
    password_copied_successfully: 'Password successfully copied!',
    no_information_at_the_moment: 'No information for now.',
    storage: 'Storage',
    storage_status: 'Free space',
    recent_documents_deposited_by_fiducial: 'Documents recently dropped by Fiducial',
    display_file_location: 'Show file location',
    no_file_last_35_days: 'No files have been dropped in the last 35 days.',
    last_35_days: '(Last 35 days)',
    last_12_months: '(Last 12 months)',
    drop_files_here: 'Drop your files here',
    or_use_upload_button: 'Or use the “Upload file” button',
    activity: 'Activity',
    system_properties: 'System Properties',
    close: 'Close',
    type: 'Type',
    size: 'Size',
    file_integrity: 'File integrity',
    verified: 'Checked',
    corrupted_file: 'Corrupted file',
    vault_creation: 'Your safe is now created, the supply of documents by FIDUCIAL will start in the next 48h.',
    publication_rule: 'Publication rule: documents in this space are added by FIDUCIAL 45 days after their creation.',
    file_deposit: 'File deposit',
    deposit: 'Deposit',
    new_folder: 'New folder',
    download_selected: 'Download the selected files',
    delete_selected: 'Delete the selected files',
    used_storage: 'You have used {percentage}% of your storage space.',
    need_more_space: 'You need additional storage space to import files.',
    ignore: 'Ignore',
    upgrade: 'Upgrade',
    folder_deposit_not_allowed: 'Deposit of folders is not allowed',
    document_size_limit_exceeded: 'The document exceeds the allowed size limit: 100MB',
    document_has_unauthorized_characters: 'The document contains unauthorised characters',
    server_error_contact_support: 'Server error, please contact support',
    cgu_acceptance: 'Acceptance of GTS',
    document_deletion: 'Document deletion',
    confirm_document_deletion: 'Are you sure you want to delete the document',
    document_has_been_deleted: 'The document has been deleted.',
    document_deletion_error: 'An error occurred while deleting the document.',
    document_preview: 'Document Preview',
    document_not_previewable: 'Preview of this document is not possible.',
    document_update_error: 'An error occurred while updating the document.',
    document_update: 'Document modification',
    document_name: 'Document name',
    document_upload_error: 'An error occurred while uploading the document.',
    document_upload_filename_too_big_error: "The filename it's too big, please, try another smaller one.",
    document_non_compliant: 'The document is not compliant.',
    mandatory_document_name: 'The name of the document is mandatory',
    document_updated: 'The document has been updated.',
    folder_creation: 'Folder creation',
    folder_deletion: 'Folder deletion',
    folder_update: 'Folder modification',
    folder_has_been_created: 'Folder has been created.',
    folder_creation_error: 'An error occurred while creating the folder.',
    folder_name: 'Folder name',
    folder_has_been_deleted: 'The folder has been deleted.',
    folder_deletion_error: 'An error occurred while deleting the folder.',
    confirm_folder_deletion: 'Are you sure you want to delete the <strong>{folderName}</strong> folder and everything in it <br>(<strong>{nbChildren} item(s)</strong>) ?',
    folder_has_been_updated: 'The folder has been updated.',
    folder_update_error: 'An error occurred while updating the folder.',
    selection_deletion: 'Deletion of the selection',
    confirm_selection_deletion: 'Are you sure you want to delete the selection?',
    selection_has_been_deleted: 'The selection has been deleted.',
    selection_deletion_error: 'An error occurred while deleting the selection.',
    confirm_multiple_deletion: 'Are you sure you want to delete the selection <strong>{count}</strong> item(s)?',
    folder_and_content: 'The <strong>{folderName}</strong> folder and everything in it(<strong>{nbChildren} item(s)</strong>) ',
    document_name_extension: 'The document <strong>{documentName}.{documentExtension}</strong>',
    no_selection: 'No selection made',
    ok: 'OK',
    your_session_will_expire: 'Your session will expire',
    you_will_be_automatically_logged_out_in: 'You will be automatically logged out in',
    second: 'second',
    seconds: 'seconds',
    extend_my_session: 'Extend my session',
    document: 'Document',
    folder: 'Folder',
    element: 'item',
    dont_show_again: 'Don\'t show again',
    user_update_error: 'An error occurred while changing the user.',
    secret_creation: 'Creation of a Secret',
    secret_name_mandatory: 'The name of the secret is mandatory',
    secret_has_been_created: 'The secret has been created.',
    secret_creation_error: 'An error occurred while creating the secret.',
    secret_name: 'Name of the secret',
    secret_url: 'URL',
    secret_identifier: 'Login',
    secret_password: 'Password',
    secret_comment: 'Comment',
    mandatory_field: '* field is mandatory',
    url_format_error: 'The URL format is invalid. The URL must start with http://, https:// or a subdomain (www.).',
    secret_deletion: 'Deletion of secret',
    confirm_delete_secret: 'Are you sure you want to delete the secret <strong>{secretName}</strong> ?',
    secret_has_been_deleted: 'The secret has been deleted.',
    secret_deletion_error: 'An error occurred while deleting the secret.',
    secret_update: 'Modification of a secret',
    secret_has_been_updated: 'The secret has been changed.',
    secret_update_error: 'An error occurred while changing the secret.',
    secret_view: 'Viewing a secret',
    none_m: 'None',
    none_f: 'None',
    delete_secret: 'Delete the secret',
    update_secret: 'Update the secret',
    url_copy_success: 'URL successfully copied!',
    username_copy_success: 'Username successfully copied!',
    password_copy_success: 'Password successfully copied!',
    comment_copy_success: 'Comment successfully copied!',
    document_deletion_definitive: 'Irrevocable deletion of the document',
    permanent_document_deletion: 'You are about to permanently delete the <strong>{itemName}</strong> document',
    document_has_been_permanently_deleted: 'The document has been permanently deleted.',
    folder_delete_definitive: 'Irrevocable deletion of folder',
    permanent_folder_deletion: 'You are about to permanently delete the <strong>{folderName}</strong> folder and everything in it(<strong>{nbChildren} item(s)</strong>)',
    folder_has_been_permanently_deleted: 'The folder has been permanently deleted.',
    secret_deletion_definitive: 'Irrevocable deletion of secret',
    permanent_secret_deletion: 'You are about to permanently delete the <strong>{itemName}</strong> secret',
    secret_has_been_permanently_deleted: 'The secret has been permanently deleted.',
    selection_deletion_definitive: 'Irrevocable deletion of the selection',
    permanent_selection_deletion: 'Are you sure you want to permanently delete the selection:',
    selection_has_been_permanently_deleted: 'The selection has been permanently deleted.',
    trash_folder: 'The <strong>{folderName}</strong> folder and everything in it(<strong>{nbChildren} item(s)</strong>) ',
    trash_document: 'The <strong>{itemName}</strong> document',
    trash_secret: 'The <strong>{itemName}</strong> secret',
    trash_deletion: 'Deleting items from trash',
    trash_deletion_definitive: 'You are about to irrevocably delete all the trash items:',
    trash_has_been_permanently_deleted: 'Trash has been emptied.',
    trash_deletion_error: 'An error occurred while deleting items.',
    confirm_user_invite: 'Are you sure you want to invite <strong>{userName}</strong> ?',
    mandatory_code: 'Code is mandatory',
    expiration_date: 'Expiration date (if field empty: no time limit for access)',
    last_payslip_title: 'Last payslip',
    last_payslip_date: 'Date of last added payslip : {createdAt}',
    no_payslip_error: 'There is no payslip.',
    copy_link: 'Copy the link',
    no_reset_request: 'No pending request for reset',
    generate_new_link: 'Generate a new link',
    generate_link: 'Generate a link',
    link_copied: 'Link copied',
    link_copy_failed: 'Impossible to copy the link',
    presentation_videos: 'Presentation Videos',
    no_more_videos: 'Don\'t show videos again',
    previous: 'Previous',
    skip: 'Skip',
    carousel_title_1: '1/4 - What is an eSafe?',
    carousel_title_2: '2/4 - Why use Mon eCoffre par FIDUCIAL?',
    carousel_title_3: '3/4 - What are the features of the eSafe?',
    carousel_title_4: '4/4 - Comment organiser mon eCoffre et quels documents stocker ?',
    two_factor_authentication_title: 'Two-factor authentication',
    two_factor_authentication_introduction: 'To continue the identification, please enter the access code issued by your two-factor application: Google Authenticator, Microsoft Authenticator, etc.',
    two_factor_authentication_access_code: 'Access code',
    two_factor_authentication_reset: 'Reset access code ?',
    mandatory_two_factor_authentication_code: 'The access code is mandatory',
    two_factor_authentication_reset_title: 'Resetting the QR Code',
    two_factor_authentication_reset_submit: 'Resetting the QR Code',
    two_factor_authentication_reset_introduction: 'If you have lost your QR Code, complete the form below to receive an email allowing you to reset it.',
    two_factor_authentication_activation_title: 'Enabling two-factor authentication (2FA)',
    two_factor_authentication_activation_introduction_1: 'Two-step validation, also called ',
    two_factor_authentication_activation_introduction_2: ', allows you to add an extra level of security to protect your account in case your password is stolen.',
    two_factor_authentication_activation_introduction_3: 'After enabling this feature, you can log in to your account using:',
    two_factor_authentication_activation_introduction_4: '- Your login/password',
    two_factor_authentication_activation_introduction_5: '+ Your phone (with a third-party application like "Google Authenticator", "Microsoft Authenticator", etc.)',
    two_factor_authentication_activation_introduction_link: '"two-factor authentication"',
    two_factor_authentication_activated: 'You have subscribed to two-factor authentication.',
    two_factor_authentication_deactivated: 'You have not subscribed to two-factor authentication.',
    two_factor_authentication_activation_scan_1: 'Scan the code below with the Google Authenticator application, Microsoft Authenticator, etc... to obtain your access code',
    two_factor_authentication_activation_scan_2: 'You must enter a code obtained via the application to validate the two-factor authentication.',
    two_factor_authentication_updated: 'Your authentication options have been updated.',
    old_name: 'Old name',
    new_name: 'New name',
    confirm_delete_selected_items: 'Are you confirming the deletion of the item selection',
    last_30_days: 'Last 30 days',
    last_6_months: 'Last 6 months',
    one_one: '1 connection',
    two_nine: 'Between 2 and 9 connections',
    between_10_29: 'Between 10 and 29 connections',
    more_than_30: '30 or more than connections'
  }
}
