export default {
  message: {
    welcome: 'Bienvenue sur notre application!',
    dashboard: 'Tableau de bord',
    my_secrets: 'Mes secrets',
    my_company_secrets: 'Mes secrets entreprise',
    my_documents: 'Mes documents',
    documents: 'Documents',
    trash: 'Corbeille',
    logs: 'Historique des actions',
    vault_management: 'Gestion du Coffre',
    help: 'Aide',
    release_logs: 'Nouveautés',
    faq: 'FAQ',
    user_guide: 'Mode d’emploi',
    contact: 'Contact',
    legal_notices: 'Mentions légales',
    cgu: 'CGU',
    admin: 'Administration',
    collaborators_management: 'Gestion des collaborateurs',
    personal_storage_space: 'ESPACE DE STOCKAGE PERSONNEL',
    buy_storage: 'Acheter du stockage',
    employee_management: 'Gestion des salariés',
    page_management: 'Gestion des pages',
    release_log_management: 'Gestion des nouveautés',
    statistics: 'Gestion des statistiques',
    statistics_vault_chart: 'Nombre des Coffres',
    statistics_connection_chart: 'Nombre des Connexions',
    statistics_document_chart: 'Nombre des Documents',
    statistics_secret_chart: 'Nombre des Secrets',
    massive_upload: 'Dépôt massif',
    usage: '{used} Mo utilisés sur {max} Go',
    percent_used: '{percent}% utilisés',
    file_singular: '{count} fichier',
    file_plural: '{count} fichiers',
    section_payslip: 'Bulletins de paie',
    section_individual_com: 'Communication individuelle',
    section_general_com: 'Communication générale',
    section_personal: 'Personnel',
    section_bils: 'Facture Parc Informatique et Téléphonique',
    section_contracts: 'Contrats',
    section_quote: 'Devis',
    section_accounting: 'Comptabilité',
    section_internal_com: 'Communication interne',
    section_fiducial_documents: 'Documents FIDUCIAL',
    section_my_company: 'Mon entreprise',
    section_secrets: 'Mes secrets',
    master_key: 'Clé maître',
    master_key_init: 'Initialisation',
    master_key_update: 'Modification',
    master_key_check: 'Vérification',
    search: 'Recherche',
    all_rights_reserved: 'Tous droits réservés',
    files_import: 'Importation de fichiers',
    login: 'Se connecter',
    log_out: 'Se déconnecter',
    edit_your_email: 'Modifier votre e-mail',
    edit_your_password: 'Modifier votre mot de passe',
    notification_options: 'Options de notifications',
    subscription_services: 'Souscription aux Services',
    trusted_third_party: 'Tiers de confiance',
    two_factor_authentication: 'Authentification à double facteur',
    my_account: 'Mon compte',
    my_collaborators: 'Mes collaborateurs',
    others: 'Autres',
    services: 'Services',
    discover_also: 'Découvrez également',
    employee_or_former_fiducial: 'Je suis salarié ou ancien salarié de FIDUCIAL',
    fiducial_client: 'Je suis client FIDUCIAL',
    session_expired: 'Votre session a expiré, veuillez vous reconnecter',
    forgot_password: 'Mot de passe oublié ?',
    not_registered_yet: 'Vous n\'êtes pas encore inscrit(e) ?',
    account_activation_via_temp: 'Activation du compte via votre identifiant temporaire',
    employee_contact: 'Contact Salarié',
    client_contact: 'Contact Client: se rapprocher de votre agence',
    fiducial_login: 'Me Connecter avec mon compte Fiducial',
    label_email: 'E-mail',
    label_password: 'Mot de passe',
    label_password_confirmation: 'Confirmation du mot de passe',
    label_name: 'Nom',
    label_url: 'Url',
    label_login: 'Identifiant',
    label_comment: 'Commentaire',
    password_reset_instructions: 'Si votre adresse est référencée dans la base, un email de ré-initialisation vient de vous être envoyé. Cliquez sur le lien dans cet email pour ré-initialiser votre mot de passe.',
    account_blocked: 'Compte bloqué pour 15min.',
    account_step_1_desc: 'Avant de débuter l\'activation au service, assurez-vous d\'avoir accès à votre messagerie personnelle sur laquelle un email de confirmation valable 3 heures vous sera envoyé',
    temp_login: 'Saisir votre Identifiant temporaire.',
    temp_password: 'Mot de passe temporaire.',
    back_to_login: 'Retour à la connexion',
    next: 'Suivant',
    activation_step_1: 'Activation étape 1',
    mandatory_login: 'L\'identifiant est obligatoire',
    mandatory_password: 'Le mot de passe est obligatoire',
    mandatory_current_password: 'Le mot de passe actuel est obligatoire',
    mandatory_password_confirmation: 'La confirmation du mot de passe est obligatoire',
    mandatory_email: 'L\'e-mail est obligatoire',
    mandatory_valid_email: 'L\'e-mail doit être valide',
    mandatory_format_valid_email: 'L\'e-mail doit avoir un format valide',
    mandatory_first_name: 'Le prénom est obligatoire',
    mandatory_last_name: 'Le nom est obligatoire',
    mandatory_address: 'L\'adresse est obligatoire',
    mandatory_phone: 'Le téléphone est obligatoire',
    mandatory_message: 'Le message est obligatoire',
    account_step_2_desc: 'Afin d\'activer votre compte, vous devez renseigner votre e-mail et définir votre propre mot de passe.',
    activation_step_2: 'Activation étape 2',
    validate: 'Valider',
    password_must_match: 'Les mots de passe doivent correspondre',
    password_must_be_different_email: 'Le mot de passe doit être différent de l\'e-mail',
    password_not_strong_enough: 'Le mot de passe n\'est pas assez complexe.',
    minimal_password_level: 'Niveau de sécurité minimum du mot de passe',
    weak: 'Faible',
    strong: 'Fort',
    between_10_32_char: 'Entre 10 et 32 caractères',
    at_least_one_caps: 'Au moins une majuscule',
    at_least_one_lowercase: 'Au moins une minuscule',
    at_least_one_digit: 'Au moins un chiffre',
    at_least_one_special_char: 'Au moins un caractère spécial',
    redirecting: 'Redirection sur votre espace mon ecoffre en cours',
    forgot_password_title: 'Mot de passe oublié',
    fill_form_to_reset_password: 'Si vous avez oublié votre mot de passe, remplissez le formulaire ci-dessous pour recevoir un e-mail vous permettant de le réinitialiser.',
    reset_link_info: 'Le lien de réinitialisation n\'est valable que 5h. Passé ce délai, vous devrez effectuer une nouvelle demande.',
    reset_password: 'Réinitialisation du mot de passe',
    return_to_login: 'Retour à la connexion',
    activation: 'Activation',
    budget_code_intro: 'Le Coffre-fort Code Budget est un service interne à FIDUCIAL permettant de recevoir des documents nécessaires à la bonne gestion des Services des branches, des activités, des sociétés, des DGR, des DR, des DA, ... pour l’ensemble de la firme.',
    more_info: 'Pour plus d’information, merci de cliquer sur les liens suivants',
    code_budget_user_guide: 'Mode d’emploi du Coffre-fort Code Budget',
    faq_other: 'Foire aux questions (FAQ)',
    login_with_intranet: 'Me Connecter avec mon compte intranet',
    error_login: 'Une erreur est survenue lors de la connexion. Veuillez réessayer plus tard.',
    no_budget_code: 'Aucun coffre-fort',
    access_expired: 'Votre accès pour ce Coffre-fort a expiré.',
    redirecting_to_your_space: 'Redirection sur votre espace mon ecoffre en cours',
    connection_error: 'Une erreur est survenue lors de la connexion. Veuillez réessayer plus tard.',
    no_access_granted: 'Aucun accès n\'a été autorisé pour ce Coffre-fort.',
    reset_password_info: 'Suite à votre demande de réinitialisation, merci de saisir votre nouveau mot de passe.',
    new_password: 'Nouveau mot de passe',
    confirm_new_password: 'Confirmation du nouveau mot de passe',
    security_policy_text: "Dans le cadre de notre politique d'amélioration de la sécurité, nous vous demandons de changer votre mot de passe en respectant les critères ci-dessous.",
    update_current_password: 'Mise à jour du mot de passe',
    change_password: 'Modification mot de passe',
    fill_out_form: 'Remplissez le formulaire ci-dessous pour modifier votre mot de passe.',
    current_password: 'Mot de passe actuel',
    change_email: 'Modification de l\'e-mail',
    currently_logged: 'Vous êtes actuellement connecté avec le e-mail suivant :',
    email_usage: 'Votre e-mail est votre identifiant de connexion. Il est aussi utilisé pour vous envoyer les notifications relatives à votre compte.',
    validate_email: 'Validation Email',
    validate_token: 'Validation Token',
    admin_collaborators: 'Administration des collaborateurs',
    no_collaborator: 'Vous n\'avez actuellement aucun collaborateur disposant d\'un compte pour ce coffre. Vous pouvez ajouter un collaborateur dans',
    your_fiducial_account: 'votre compte Fiducial.',
    collaborator: 'Collaborateur',
    email: 'Email',
    created_at: 'Crée le',
    last_login: 'Dernière connexion',
    clearance_added: 'L\'habilitation a été ajoutée.',
    clearance_revoked: 'L\'habilitation a été révoquée.',
    error_clearance_deletion: 'Une erreur est survenue lors de la suppression de l\'habilitation. Veuillez réessayer plus tard.',
    invite_collaborator: 'Inviter un collaborateur',
    no_collaborators: 'Aucun collaborateur disponible',
    owner: 'Propriétaire',
    delete: 'Supprimer',
    expires_on: 'Expire le',
    no_date: 'aucune date',
    last_connection: 'Dernière connexion',
    administrators: 'Administrateurs',
    edit: 'Modifier',
    invited_by: 'Invité par',
    users: 'Users',
    expired: 'Expirés',
    delete_access: 'Supprimer l\'accès',
    user_invitation: 'Invitation d\'utilisateur',
    modify_expiration_date: 'Modifier la date d\'expiration',
    email_star: 'E-mail*',
    expiration_date_limitation: 'Date d’expiration (si champ vide: pas de limite de temps pour l\'accès)',
    user_type: 'Type d\'utilisateur',
    type_required: 'Le type doit être renseigné',
    cancel: 'Annuler',
    update_saved: 'La mise à jour a été enregistrée.',
    error: 'Erreur',
    contact_support: 'Contactez le support',
    new_request: 'Faire une nouvelle demande',
    are_you_employee: 'Etes-vous salarié(e) ?',
    yes: 'Oui',
    no: 'Non',
    access_issue: 'Uniquement en cas d’impossibilité d\'accéder aux Workflows Intranet,',
    click_here: 'cliquez ici',
    click_here_login_issue: 'Click here if you can\'t log in',
    other_problems: 'Pour tout autre problème, merci de saisir sur Intranet un workflow « Informatique et téléphonie : Assistance : panne, dysfonctionnement, autres. »',
    no_access_intranet: 'Si vous n\'avez pas accès à l\'intranet ou à ce workflow, demandez à votre manager de faire la demande.',
    workflow_reminder: 'Nous vous rappelons que vous devez saisir votre demande par Workflow si vous avez accès à l\'intranet Fiducial.',
    last_name: 'Nom',
    first_name: 'Prénom',
    employee_id: 'Matricule',
    phone: 'Téléphone',
    category: 'Catégorie',
    category_required: 'La catégorie doit être renseignée',
    message: 'Message',
    agreement: 'En validant, j\'accepte le traitement de mes données à caractère personnel conformément à la',
    privacy_policy_link: '/privacy-policy',
    privacy_policy: 'politique de confidentialité de Fiducial Cloud',
    send: 'Send',
    category_account_creation: 'Création de compte',
    category_login: 'Connexion',
    category_document_reception: 'Réception de documents',
    category_account_management: 'Gestion de compte',
    category_personal_data_management: 'Gestion de mes données personnelles',
    category_missing_pay_slip: 'Bulletin de paie manquant',
    category_error_message: 'Message d\'erreur',
    category_other_issues: 'Autre(s) souci(s)',
    connection_issue_instructions: 'Pour tout problème de connexion, merci d’utiliser les procédures standards d’interventions (Workflow Assistance : Panne, dysfonctionnement, autres).',
    new_access_requests: 'Concernant les nouvelles demandes d’accès à un coffre existant, 3 solutions sont possibles (voir Mode d’emploi) :',
    delegation_rights_option_1: 'Délégation des droits par l’ancien propriétaire du Coffre vers le nouveau responsable du code budget (Invitation avec le type d’utilisateur «Administrateurs»).',
    delegation_rights_option_2: 'Délégation des droits par le responsable du code budget actuel pour un collaborateur (Invitation avec le type d’utilisateur «Utilisateurs»).',
    delegation_rights_option_3: 'Si un nouveau responsable de code budget n’a pas pu avoir accès au coffre par délégation, faire une demande par email à l’adresse : gestion.interne.parc.materiel.informatique@fiducial.fr',
    message_received: 'Votre message a bien été pris en compte.',
    fiducial_privacy_policy: 'Politique de confidentialité',
    terms_of_use: 'Conditions Générales d’Utilisation de Mon eCoffre par FIDUCIAL',
    presentation_my_ecoffre: 'Présentation de Mon eCoffre',
    presentation_coffre_code_budget: 'Présentation du Coffre-fort Code Budget',
    presentation_paragraph_1: 'Mon eCoffre par FIDUCIAL est un coffre-fort en ligne permettant de sauvegarder et protéger vos données et documents importants dans un espace hautement sécurisé. Mon eCoffre par FIDUCIAL est une plateforme accessible depuis n\'importe quel terminal connecté à internet. Conformément à la loi, la remise des documents par FIDUCIAL s’effectue dans des conditions qui garantissent l’intégrité des données',
    ecoffre_url: 'https://www.mon-ecoffre.com',
    presentation_paragraph_2: 'L\'utilisateur recevra une notification par email à chaque dépôt effectué par FIDUCIAL dans le coffre-fort. Pour plus de questions concernant l\'utilisation du eCoffre, rendez-vous dans les différentes rubriques de notre FAQ.',
    presentation_paragraph_3: 'Le Coffre-fort Code Budget est un service interne à FIDUCIAL permettant de recevoir des documents nécessaires à la bonne gestion des Services des branches, des activités, des sociétés, des DGR, des DR, des DA etc … pour l’ensemble des entités et sociétés de la firme. Pour plus d’information, merci de cliquer sur le lien ci-contre pour',
    code_budget_user_guide_2: 'le mode d’emploi du Coffre-fort Code Budget',
    code_budget_user_guide_url: '/mode_emploi_Coffre_Code_Budget.pdf',
    view_presentation_videos: 'Voir les vidéos de présentation',
    frequently_asked_questions: 'Questions fréquentes',
    my_ecoffre: 'Mon eCoffre',
    notification_email_login: 'La notification par e-mail lors d\'une connexion à votre compte est',
    notification_email_new_document: 'La notification par e-mail lors du dépôt d\'un nouveau document dans votre coffre-fort est',
    business: 'entreprise',
    notification_email_secret_creation: 'La notification par e-mail lors d\'une création de secret dans votre coffre-fort est',
    notification_email_secret_deletion: 'La notification par e-mail lors d\'une suppression d\'un secret dans votre coffre-fort est',
    notification_email_secret_view: 'La notification par e-mail lors d\'une consultation d\'un secret dans votre coffre-fort est',
    notification_email_secret_update_by_owner: 'La notification par e-mail lors d\'une modification par vous même d\'un secret dans votre coffre-fort est',
    notification_email_secret_update_by_collab: 'La notification par e-mail lors d\'une modification par l\'un de vos collaborateurs d\'un secret dans votre coffre-fort est',
    token: 'Renseigner un code obtenu avec l\'application pour valider l\'authentification à double facteur',
    to_enable: 'Activer',
    activated: 'Activé',
    deactivated: 'Désactivé',
    notification_option_updated: 'Votre option de notification a été mise à jour.',
    service_subscription: 'Souscription aux services',
    dematerialization_of_pay_slips: 'Dématérialisation des bulletins de paie',
    subscribed_to_dematerialization: 'Vous avez souscrit à la dématérialisation de vos bulletins de paie.',
    not_subscribed_to_dematerialization: 'Vous n’avez pas souscrit à la dématérialisation de vos bulletins de paie. Vous les recevrez par voie postale à votre domicile.',
    service_coming_soon: 'Service Coming Soon',
    subscription_updated: 'Votre souscription a été mise à jour.',
    trusted_third_party_optional: 'Tiers de confiance (facultatif)',
    trusted_third_party_info: 'Vous avez la possibilité de renseigner, à tout moment, les informations d\'une personne de confiance concernant l\'accès à votre coffre-fort électronique.',
    you_have_designated_as_trusted: 'Vous avez désigné',
    address: 'Adresse',
    validate_information: 'Valider les informations',
    trusted_third_party_created: 'Le tiers de confiance a été crée.',
    trusted_third_party_updated: 'Le tiers de confiance a été mis à jour.',
    date_from: 'Du',
    date_to: 'Au',
    search_2: 'Rechercher',
    reset: 'Réinitialisation',
    date_hour: 'Date heure',
    action: 'Action',
    user: 'Utilisateur',
    ip: 'IP',
    browser: 'Navigateur',
    details: 'Détails',
    elements: 'élément(s)',
    see_more: 'Voir plus',
    updated_fields: 'Champ(s) modifié(s)',
    copied_fields: 'Champ(s) copié(s)',
    field_name: 'Nom',
    field_url: 'Url',
    field_login: 'Identifiant',
    field_password: 'Mot de passe',
    field_comment: 'Commentaire',
    type_document: 'Document',
    type_folder: 'Dossier',
    type_secret: 'Secret',
    name: 'Nom',
    deposit_date: 'Date de dépôt',
    actions: 'Actions',
    download: 'Télécharger',
    view_file_location: 'Afficher l\'emplacement du fichier',
    view: 'Voir',
    information: 'Informations',
    service_maintenance: 'Le service est actuellement en maintenance, vos documents ne sont pas consultables pour le moment.',
    error_fetching_items: 'Une erreur s\'est produite lors de la récupération des éléments.',
    trash_date: 'Date de mise en corbeille',
    automatic_deletion_date: 'Date de suppression automatique',
    restore_selection: 'Restaurer la sélection',
    delete_selection_perma: 'Supprimer définitivement la sélection',
    empty_trash: 'Vider la corbeille',
    document_restoration_error: 'Une erreur est intervenue lors de la restauration du document.',
    elements_restoration_error: 'Une erreur est intervenue lors de la restauration des éléments.',
    create_secret: 'Création d\'un secret',
    create_new_folder: 'Créer un nouveau dossier',
    delete_selected_secrets: 'Supprimer les secrets sélectionnés',
    delete_selection: 'Supprimer la sélection',
    move_selection: 'Déplacer la sélection',
    creation_date: 'Date de création',
    new: 'Nouveau',
    drop_date: 'Date de dépôt',
    rename: 'Renommer',
    copy_password: 'Copier le mot de passe',
    copy_username: 'Copier l\'identifiant',
    copy_url: 'Copier l\'URL',
    copy_comment: 'Copier le commentaire',
    mandatory_folder_name: 'Le nom est obligatoire',
    selection_moved: 'La sélection a été déplacée.',
    error_moving_selection: 'Une erreur est intervenue lors du déplacement de la sélection.',
    error_retrieving_items: 'Une erreur s\'est produite lors de la récupération des éléments.',
    maintenance_secrets_unavailable: 'Le service est actuellement en maintenance, vos secrets ne sont pas consultables pour le moment.',
    url_copied_successfully: 'Url copié avec succès !',
    username_copied_successfully: 'Identifiant copié avec succès !',
    password_copied_successfully: 'Mot de passe copié avec succès !',
    no_information_at_the_moment: 'Aucune information pour le moment.',
    storage: 'Stockage',
    storage_status: 'Espace libre',
    recent_documents_deposited_by_fiducial: 'Documents récents déposés par Fiducial',
    display_file_location: 'Afficher l\'emplacement du fichier',
    no_file_last_35_days: 'Aucun fichier n\'a été déposé au cours des 35 derniers jours.',
    last_35_days: '(35 derniers jours)',
    last_12_months: '(12 derniers mois)',
    drop_files_here: 'Déposez vos fichiers ici',
    or_use_upload_button: 'ou utilisez le bouton "Dépôt de fichier"',
    activity: 'Activité',
    system_properties: 'Propriétés système',
    close: 'Fermer',
    type: 'Type',
    size: 'Taille',
    file_integrity: 'Intégrité du fichier',
    verified: 'Vérifié',
    corrupted_file: 'Fichier corrompu',
    vault_creation: 'Votre coffre-fort est maintenant créé, l’alimentation de celui-ci par FIDUCIAL démarrera dans les prochaines 48H.',
    publication_rule: 'Règle de publication : les documents de cet espace sont ajoutés par FIDUCIAL 45 jours après leur création.',
    file_deposit: 'Dépôt de fichier',
    deposit: 'Déposer',
    new_folder: 'Nouveau dossier',
    download_selected: 'Télécharger les fichiers sélectionnés',
    delete_selected: 'Supprimer les fichiers sélectionnés',
    used_storage: 'You have used {percentage}% of your storage space.',
    need_more_space: 'You need additional storage space to import files.',
    ignore: 'Ignore',
    upgrade: 'Upgrade',
    folder_deposit_not_allowed: 'Le dépôt de dossiers n\'est pas autorisé',
    document_size_limit_exceeded: 'Le document dépasse la limite de taille autorisée : 100Mo',
    document_has_unauthorized_characters: 'Le document comporte des caractères non autorisés',
    server_error_contact_support: 'Erreur serveur, veuillez contacter le support',
    cgu_acceptance: 'Acceptation des CGU',
    document_deletion: 'Suppression de document',
    confirm_document_deletion: 'Etes-vous sûr de vouloir supprimer le document',
    document_has_been_deleted: 'Le document a été supprimé.',
    document_deletion_error: 'Une erreur est intervenue lors de la suppression du document.',
    document_preview: 'Prévisualisation de document',
    document_not_previewable: 'Ce document n\'est pas prévisualisable.',
    document_update_error: 'Une erreur est intervenue lors de la mise à jour du document.',
    document_update: 'Modification de document',
    document_name: 'Nom du document',
    document_upload_error: 'Une erreur est survenue lors du dépôt du document',
    document_upload_filename_too_big_error: "Le nom du document est trop grand. Il doit faire moins de 200 caractères.",
    document_non_compliant: 'Le document n\'est pas conforme.',
    mandatory_document_name: 'Le nom du document est obligatoire',
    document_updated: 'Le document a été mis à été modifié.',
    folder_creation: 'Création de dossier',
    folder_deletion: 'Suppression de dossier',
    folder_update: 'Modification de dossier',
    folder_has_been_created: 'Le dossier a été créé.',
    folder_creation_error: 'Une erreur est intervenue lors de la création du dossier.',
    folder_name: 'Nom du dossier',
    folder_has_been_deleted: 'Le dossier a été supprimé.',
    folder_deletion_error: 'Une erreur est intervenue lors de la suppression du dossier.',
    confirm_folder_deletion: 'Etes-vous sûr de vouloir supprimer le dossier <strong>{folderName}</strong> et tout ce qu’il contient <br>(<strong>{nbChildren} élément(s)</strong>) ?',
    folder_has_been_updated: 'Le dossier a été modifié.',
    folder_update_error: 'Une erreur est intervenue lors de la modification du dossier.',
    selection_deletion: 'Suppression de la sélection',
    confirm_selection_deletion: 'Etes-vous sûr de vouloir supprimer la sélection ?',
    selection_has_been_deleted: 'La sélection a été supprimée.',
    selection_deletion_error: 'Une erreur est intervenue lors de la suppression de la sélection.',
    confirm_multiple_deletion: 'Etes-vous sûr de vouloir supprimer la sélection <strong>{count}</strong> élément(s) ?',
    folder_and_content: 'Le dossier <strong>{folderName}</strong> et tout ce qu’il contient (<strong>{nbChildren} élément(s)</strong>)',
    document_name_extension: 'Le document <strong>{documentName}.{documentExtension}</strong>',
    no_selection: 'Aucune sélection réalisée',
    ok: 'OK',
    your_session_will_expire: 'Votre session va expirer',
    you_will_be_automatically_logged_out_in: 'Vous allez être automatiquement déconnecté dans',
    second: 'seconde',
    seconds: 'secondes',
    extend_my_session: 'Prolonger ma session',
    document: 'Document',
    folder: 'Dossier',
    element: 'élément',
    dont_show_again: 'Ne plus afficher',
    user_update_error: 'Une erreur est intervenue lors de la modification de l\'utilisateur.',
    secret_creation: 'Création d\'un secret',
    secret_name_mandatory: 'Le nom du secret est obligatoire',
    secret_has_been_created: 'Le secret a été créé.',
    secret_creation_error: 'Une erreur est intervenue lors de la création du secret.',
    secret_name: 'Nom du secret',
    secret_url: 'URL',
    secret_identifier: 'Identifiant',
    secret_password: 'Mot de passe',
    secret_comment: 'Commentaire',
    mandatory_field: '* saisie obligatoire',
    url_format_error: 'Le format de l\'URL n\'est pas valide. L\'URL doit commencer par http://, https:// ou un sous domaine (www.).',
    secret_deletion: 'Suppression de secret',
    confirm_delete_secret: 'Êtes-vous sûr de vouloir supprimer le secret <strong>{secretName}</strong> ?',
    secret_has_been_deleted: 'Le secret a été supprimé.',
    secret_deletion_error: 'Une erreur est intervenue lors de la suppression du secret.',
    secret_update: 'Modification d\'un secret',
    secret_has_been_updated: 'Le secret a été modifié.',
    secret_update_error: 'Une erreur est intervenue lors de la modification du secret.',
    secret_view: 'Visualisation d\'un secret',
    none_m: 'Aucun',
    none_f: 'Aucune',
    delete_secret: 'Supprimer le secret',
    update_secret: 'Modifier le secret',
    url_copy_success: 'Url copiée avec succès !',
    username_copy_success: 'Identifiant copié avec succès !',
    password_copy_success: 'Mot de passe copié avec succès !',
    comment_copy_success: 'Commentaire copié avec succès !',
    document_deletion_definitive: 'Suppression définitive de document',
    permanent_document_deletion: 'Vous êtes sur le point de supprimer définitivement le document <strong>{itemName}</strong>',
    document_has_been_permanently_deleted: 'Le document a été définitivement supprimé.',
    folder_delete_definitive: 'Suppression définitive de dossier',
    permanent_folder_deletion: 'Vous êtes sur le point de supprimer définitivement le dossier <strong>{itemName}</strong> et tout ce qu’il contient (<strong>{nbChildren} élément(s)</strong>)',
    folder_has_been_permanently_deleted: 'Le dossier a été définitivement supprimé.',
    secret_deletion_definitive: 'Suppression définitive de secret',
    permanent_secret_deletion: 'Vous êtes sur le point de supprimer définitivement le secret <strong>{itemName}</strong>',
    secret_has_been_permanently_deleted: 'Le secret a été définitivement supprimé.',
    selection_deletion_definitive: 'Suppression définitive de la sélection',
    permanent_selection_deletion: 'Etes-vous sûrs de vouloir définitivement supprimer la sélection :',
    selection_has_been_permanently_deleted: 'La sélection a été définitivement supprimée.',
    trash_folder: 'Le dossier <strong>{itemName}</strong> et tout ce qu’il contient (<strong>{childrenLength} élément(s)</strong>)',
    trash_document: 'Le document <strong>{itemName}</strong>',
    trash_secret: 'Le secret <strong>{itemName}</strong>',
    trash_deletion: 'Suppression des éléments de la corbeille',
    trash_deletion_definitive: 'Vous êtes sur le point de supprimer définitivement l’ensemble du contenu de la corbeille :',
    trash_has_been_permanently_deleted: 'La corbeille a été vidée.',
    trash_deletion_error: 'Une erreur est intervenue lors de la suppression des éléments.',
    confirm_user_invite: 'Etes-vous sûrs de vouloir inviter <strong>{userName}</strong> ?',
    mandatory_code: 'Le code doit être renseignée',
    expiration_date: 'Date d’expiration (si champ vide: pas de limite de temps pour l\'accès)',
    last_payslip_title: 'Dernier bulletin de paie',
    last_payslip_date: 'Date du dernier bulletin de paie déposé : {createdAt}',
    no_payslip_error: 'Aucun bulletin de paie n\'est présent.',
    copy_link: 'Copier le lien',
    no_reset_request: 'Aucune demande de réinitialisation en cours',
    generate_new_link: 'Générer un nouveau lien',
    generate_link: 'Générer un lien',
    link_copied: 'Lien copié',
    link_copy_failed: 'Impossible de copier le lien',
    presentation_videos: 'Vidéos de présentation',
    no_more_videos: 'Ne plus afficher les vidéos',
    previous: 'Précédent',
    skip: 'Passer',
    carousel_title_1: '1/4 - Qu\'est-ce qu\'un coffre-fort numérique ?',
    carousel_title_2: '2/4 - Pourquoi utiliser Mon eCoffre par FIDUCIAL ?',
    carousel_title_3: '3/4 - Quelles sont les fonctionnalités du eCoffre ?',
    carousel_title_4: '4/4 - Comment organiser mon eCoffre et quels documents stocker ?',
    two_factor_authentication_title: 'Authentification à double facteur',
    two_factor_authentication_introduction: 'Afin de poursuivre l\'identification veuillez renseigner le code d\'accès délivré par votre application à double facteur : Google Authenticator, Microsoft Authenticator...',
    two_factor_authentication_access_code: 'Code d\'accès',
    two_factor_authentication_reset: 'Réiniatiliser le code ?',
    mandatory_two_factor_authentication_code: 'Le code d\'accès est obligatoire',
    two_factor_authentication_reset_title: 'Réinitialisation du QR Code',
    two_factor_authentication_reset_submit: 'Réinitialisation du QR Code',
    two_factor_authentication_reset_introduction: 'Si vous avez perdu votre QR Code, remplissez le formulaire ci-dessous pour recevoir un e-mail vous permettant de le réinitialiser.',
    two_factor_authentication_activation_title: 'Activation de l\'authentification à double facteur (2FA)',
    two_factor_authentication_activation_introduction_1: 'La validation en deux étapes, également appelée ',
    two_factor_authentication_activation_introduction_2: ', vous permet d\'ajouter un niveau de sécurité supplémentaire afin de protéger votre compte en cas de vol de votre mot de passe.',
    two_factor_authentication_activation_introduction_3: 'Après avoir activé cette fonctionnalité, vous pouvez vous connecter à votre compte en utilisant :',
    two_factor_authentication_activation_introduction_4: '- Votre login/mot de passe',
    two_factor_authentication_activation_introduction_5: '+ Votre téléphone (avec une application tiers type : "Google Authenticator", "Microsoft Authenticator", etc...)',
    two_factor_authentication_activation_introduction_link: '"authentification à double facteur"',
    two_factor_authentication_activated: 'Vous avez souscrit à l\'authentification à double facteur.',
    two_factor_authentication_deactivated: 'Vous n\'avez pas souscrit à l\'authentification à double facteur.',
    two_factor_authentication_activation_scan_1: 'Scannez le code ci-dessous avec l\'application Google Authenticator, Microsoft Authenticator, etc... afin d\'obtenir votre code d\'accès',
    two_factor_authentication_activation_scan_2: 'Vous devez renseigner un code obtenu via l\'application pour valider l\'authentification à double facteur.',
    two_factor_authentication_updated: 'Vos options d\'authentification ont été mis à jour.',
    old_name: 'Ancien nom',
    new_name: 'Nouveau nom',
    confirm_delete_selected_items: 'Êtes-vous confirmer la suppression de la sélection des items',
    last_30_days: '30 derniers jours',
    last_6_months: '6 derniers mois',
    one_one: '1 connexion',
    two_nine: 'Entre 2 et 9 connexions',
    between_10_29: 'Entre 10 et 29 connexions',
    more_than_30: '30 et plus connexions'
  }
}
